.c-flex {
    display: flex;
    flex-wrap: wrap;
    &--nw {
        @extend .c-flex;
        flex-wrap: nowrap;
    }
    &--center {
        justify-content: center;
    }
    &--v-center {
        justify-content: center;
        align-items: center;
    }
    &--hv-center {
        justify-content: center;
        align-items: center;
    }
    &__col-10 {
        flex-basis: 10%;
        max-width: 10%;
    }
    &__col-20 {
        flex-basis: 20%;
        max-width: 20%;
    }
    &__col-25 {
        flex-basis: 25%;
        max-width: 25%;
    }
    &__col-33 {
        flex-basis: 33.3%;
        max-width: 33.3%;
    }
    &__col-30 {
        flex-basis: 30%;
        max-width: 30%;
    }
    &__col-40 {
        flex-basis: 40%;
        max-width: 40%;
    }
    &__col-50 {
        flex-basis: 50%;
        max-width: 50%;
    }
    &__col-60 {
        flex-basis: 60%;
        max-width: 60%;
    }
    &__col-70 {
        flex-basis: 70%;
        max-width: 70%;
    }
    &__col-80 {
        flex-basis: 80%;
        max-width: 80%;
    }
    &__col-90 {
        flex-basis: 90%;
        max-width: 90%;
    }
    &__col-100 {
        flex-basis: 100%;
        max-width: 100%;
    }
    &__col-auto {
        flex-basis: auto
    }
    &__col-10-sm {
        @include mq-down(m) {
            flex-basis: 10%;
            max-width: 10%;
        }
    }
    &__col-20-sm {
        @include mq-down(m) {
            flex-basis: 20%;
            max-width: 20%;
        }
    }
    &__col-25-sm {
        @include mq-down(m) {
            flex-basis: 25%;
            max-width: 25%;
        }
    }
    &__col-33-sm {
        @include mq-down(m) {
            flex-basis: 33.3%;
            max-width: 33.3%;
        }
    }
    &__col-30-sm {
        @include mq-down(m) {
            flex-basis: 30%;
            max-width: 30%;
        }
    }
    &__col-40-sm {
        @include mq-down(m) {
            flex-basis: 40%;
            max-width: 40%;
        }
    }
    &__col-50-sm {
        @include mq-down(m) {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
    &__col-60-sm {
        @include mq-down(m) {
            flex-basis: 60%;
            max-width: 60%;
        }
    }
    &__col-70-sm {
        @include mq-down(m) {
            flex-basis: 70%;
            max-width: 70%;
        }
    }
    &__col-80-sm {
        @include mq-down(m) {
            flex-basis: 80%;
            max-width: 80%;
        }
    }
    &__col-90-sm {
        @include mq-down(m) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
    &__col-100-sm {
        @include mq-down(m) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
    &__col-10-md {
        @include mq-down(l) {
            flex-basis: 10%;
            max-width: 10%;
        }
    }
    &__col-20-md {
        @include mq-down(l) {
            flex-basis: 20%;
            max-width: 20%;
        }
    }
    &__col-25-md {
        @include mq-down(l) {
            flex-basis: 25%;
            max-width: 25%;
        }
    }
    &__col-33-md {
        @include mq-down(l) {
            flex-basis: 33.3%;
            max-width: 33.3%;
        }
    }
    &__col-30-md {
        @include mq-down(l) {
            flex-basis: 30%;
            max-width: 30%;
        }
    }
    &__col-40-md {
        @include mq-down(l) {
            flex-basis: 40%;
            max-width: 40%;
        }
    }
    &__col-50-md {
        @include mq-down(l) {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
    &__col-60-md {
        @include mq-down(l) {
            flex-basis: 60%;
            max-width: 60%;
        }
    }
    &__col-70-md {
        @include mq-down(l) {
            flex-basis: 70%;
            max-width: 70%;
        }
    }
    &__col-80-md {
        @include mq-down(l) {
            flex-basis: 80%;
            max-width: 80%;
        }
    }
    &__col-90-md {
        @include mq-down(l) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
    &__col-100-md {
        @include mq-down(l) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}