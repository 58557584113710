/*----------------------------------------

	Default CSS
	default setting
	
-----------------------------------------*/

body,
div,
dl,
dt,
dd,
th,
td,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
em,
p {
    font-size: 100%;
    font-style: normal;
    font-weight: normal;
}

th {
    text-align: left;
    font-weight: normal;
}

li {
    list-style: none;
    vertical-align: bottom;
}

li img {
    vertical-align: top;
}

img,
table,
th,
td {
    border: 0;
}

hr {
    display: none;
}

input,
textarea,
select {
    color: #333333;
    font-size: 100%;
    font-family: "ãã©ã®ãè§ã´ Pro", "Hiragino Kaku Gothic Pro", "Osaka", "ã¡ã¤ãªãª", "Meiryo", "ï¼­ï¼³ ï¼°ã´ã·ãã¯", "MS P Gothic,sans-serif", sans-serif;
}


/*----------------------------------------
	body setting
-----------------------------------------*/

body {
    background-color: #fff;
    font-family: "ãã©ã®ãè§ã´ Pro", "Hiragino Kaku Gothic Pro", "Osaka", "ã¡ã¤ãªãª", "Meiryo", "ï¼­ï¼³ ï¼°ã´ã·ãã¯", "MS P Gothic,sans-serif", sans-serif;
}


/*----------------------------------------
	link setting
-----------------------------------------*/

a:link,
a:visited {
    color: #111;
    text-decoration: none;
}

a:hover,
a:active {
    color: #111;
    text-decoration: none;
}


/*----------------------------------------
	clearfix
-----------------------------------------*/

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0px;
    font-size: 0.1px;
    line-height: 0.1px;
}

.clearfix {
    display: inline-block;
    position: relative;
    /* DW */
}


/* Hides from IE-mac \*/

.clearfix {
    display: block;
}


/* End hide from IE-mac */


/* iOSã§ã®ããã©ã«ãã¹ã¿ã¤ã«ããªã»ãã */

input[type="submit"],
input[type="button"] {
    border-radius: 0;
    -webkit-box-sizing: content-box;
    -webkit-appearance: button;
    appearance: button;
    border: none;
    box-sizing: border-box;
    &::-webkit-search-decoration {
        display: none;
    }
    &::focus {
        outline-offset: -2px;
    }
}

input:not([type="radio"]),
textarea {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}