.notice_page {
    ul {
        min-height: 100vh;
        li {
            padding: 0px 20px;
            a {
                align-items: center;
                padding: 15px 0px;
                border-bottom: solid 1px #dcdcdc;
                .icon {
                    width: 30px;
                    height: 30px;
                    flex-basis: 30px;
                    border-radius: 20px;
                    overflow: hidden;
                    display: block;
                    flex-shrink: 0;
                    margin-right: 10px;
                    p {
                        width: 100%;
                        padding-top: 100%;
                    }
                }
                .content {
                    flex-grow: 1;
                    font-size: $fontSizeS;
                    margin-right: 10px;
                    .date {
                        margin-top: 5px;
                        color: #b2b4c4;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    ;
}