#about {
    color: #5d2f22;
    h2 {
        font-size: 2.0rem;
    }
    p {
        line-height: 1.8;
        font-size: $fontSizeS;
    }
    .box {
        background: white;
        padding: 20px;
        border-radius: 20px;
    }
    .button {
        background: $accentColor;
        display: block;
        font-weight: bold;
        padding: 20px;
        border: none;
        color: white;
        border-radius: 34px;
        font-size: $fontSizeM;
        text-decoration: none;
    }
    a.ul {
        text-decoration: underline;
    }
    .caption {
        h2 {
            font-size: 2.4rem;
            font-weight: bold;
        }
    }
    .hero {
        background: #f78204 url(images/about_hiro_bg.gif) no-repeat center bottom / 100% auto;
        color: white;
        height: calc(100vh - 60px);
        max-height: 540px;
        .caption {
            max-width: 320px;
            margin: 0 auto;
        }
        h1 {
            font-size: 1.4rem;
            margin-bottom: 20px;
            span {
                font-size: 2.4rem;
            }
        }
        p {
            font-size: $fontSizeS;
            line-height: 2.4;
        }
        @include mq-up(m) {
            min-height: 720px;
        }
    }
    .comparison {
        h2 {
            font-size: 2.0rem;
            font-weight: bold;
            color: $keyColor;
            margin-bottom: 20px;
        }
        .col_33 {
            @include col3Space(10px);
            p {
                font-size: 1.0rem;
            }
            img {
                width: 100%;
                height: auto;
                max-width: 186px;
                margin-bottom: 10px;
            }
        }
    }
    .feature {
        .caption {
            margin-bottom: 0;
            padding-bottom: 90px;
            background: url(images/about_feature_caption.png) no-repeat center bottom / 69px auto;
        }
        .item {
            padding-bottom: 10px;
            &.col_100 {
                .inner {
                    padding: 30px;
                    .cap {
                        margin-bottom: 20px;
                        h3 {
                            font-size: 2.0rem;
                            margin-bottom: 10px;
                        }
                    }
                    .sub {
                        .item {
                            text-align: center;
                            padding-bottom: 125px;
                            p.small {
                                font-size: 1.0rem;
                                text-align: center;
                            }
                            &:first-child {
                                background: url(images/about_feature_01_01.gif) no-repeat center bottom / 137px auto;
                            }
                            &:last-child {
                                background: url(images/about_feature_01_02.gif) no-repeat center bottom / 137px auto;
                            }
                        }
                    }
                }
            }
            &.col_50 {
                @include col2Space(10px);
            }
            .inner {
                background: white;
                border-radius: 20px;
                padding: 20px;
                height: 100%;
                h3 {
                    color: $keyColor;
                    font-size: $fontSizeM;
                    margin-bottom: 10px;
                }
                p {
                    font-size: $fontSizeS;
                    text-align: left;
                }
                img {
                    padding-top: 10px;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 40px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .recommend {
        background: white;
        ul {
            li {
                margin-bottom: 10px;
            }
        }
    }
    .flow {
        li {
            background: white;
            border-radius: 20px;
            padding: 20px;
            text-align: left;
            margin-bottom: 10px;
            h3 {
                color: $keyColor;
                margin-bottom: 20px;
                padding-bottom: 5px;
                border-bottom: solid 1px $keyColor;
            }
        }
    }
    .qa {
        li {
            background: white;
            border-radius: 20px;
            padding: 20px;
            text-align: left;
            margin-bottom: 10px;
            h3 {
                position: relative;
                padding-left: 25px;
                color: $keyColor;
                margin-bottom: 10px;
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    line-height: 1.0;
                    content: "Q";
                    font-weight: 500;
                    font-size: 2.0rem;
                }
            }
            p {
                position: relative;
                padding-left: 25px;
                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    line-height: 1.0;
                    content: "A";
                    font-weight: 500;
                    font-size: 2.0rem;
                }
            }
        }
    }
    .start {
        background: white;
        .image {
            img {
                width: 100%;
                max-width: 304px;
                height: auto;
                vertical-align: bottom;
            }
        }
    }
    &.about--teacher {
        .hero {
            background: white url(images/about_teacher_hiro_bg.gif) no-repeat center bottom / 100% auto;
            color: $accentColor;
        }
        .recommend {
            background: $accentColor;
            h2 {
                color: white;
            }
            .case {
                background: white;
                border-radius: 20px;
                padding: 30px;
                margin-bottom: 20px;
                h3 {
                    font-size: 2.0rem;
                    margin-bottom: 20px;
                    span {
                        transform: translateY(-3px);
                        display: inline-block;
                        background: $keyColor;
                        color: white;
                        padding: 2px 5px;
                        font-size: $fontSizeXS;
                        margin-right: 10px;
                    }
                }
                .desire {
                    margin-bottom: 20px;
                    img {
                        width: auto;
                        height: 100px;
                        margin-bottom: 10px;
                    }
                }
                .suggestion {
                    background: url(images/about_reccomend_case_arrow.gif) no-repeat center top / 32px auto;
                    padding-top: 50px;
                    font-size: 2.0rem;
                    color: $keyColor;
                    font-weight: bold;
                }
            }
        }
    }
}