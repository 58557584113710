.c-button {
    background: $keyColor;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: $fontSizeM;
    &--md {
        padding: 12px 20px;
    }
    &--white {
        background: white;
        border: solid 1px $keyColor;
        color: $keyColor;
    }
    &--gray {
        background: #f5f5f5;
        color: $textGray;
    }
    &--plane {
        border: none;
        background: white;
        padding: 5px 10px;
        font-size: $fontSizeS;
        border-radius: 5px;
    }
    &--cat_tab {
        background: #fff;
        border-radius: 5px;
        border: none;
        font-size: $fontSizeS;
        margin-right: 8px;
    }
    &--round {
        background: #fff;
        font-weight: bold;
        padding: 0px 20px;
        color: $accentColor;
        border: none;
        border-radius: 25px;
        font-size: $fontSizeM;
        height: 40px;
        min-width: 120px;
        &:disabled {
            opacity: 0.4;
        }
    }
    &-keycolor {
        background: $keyColor;
        color: white;
    }
    &--brown {
        background: $accentColor;
        color: white;
    }
    &--white {
        background: white;
        color: $keyColor;
        border: solid 1px $keyColor;
    }
    &--inline {
        display: inline-block;
        width: auto;
    }
}