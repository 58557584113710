html,
body {
    background: #f5f5f5;
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    color: $textColor;
}

html {
    font-size: 62.5%;
}

body {
    font-size: $fontSizeM;
}

#wrapper {
    max-width: 640px;
    margin: 0 auto;
    margin-bottom: 50px;
    min-height: 100%;
}

#main {
    background: $yellow;
    min-height: 100vh;
}

h1 {
    font-size: 2.2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

a:link,
a:visited {
    color: $textColor;
}