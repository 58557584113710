.u-key_color {
    color: $keyColor;
}

.u-bg_keycolor {
    background: $keyColor;
}

.u-bg_yellow {
    background: $yellow;
}

.u-bg_white {
    background: white !important;
}

.u-bg_lightgray {
    background: $lightGray;
}

.u-bg_accent {
    background: $accentColor !important;
}

.u-align_center {
    text-align: center;
}

.u-text-primary {
    color: $primaryColor;
}

.u-text-secondary {
    color: $secondaryColor;
}

.u-text-bold {
    font-weight: bold;
}

.u-text-small {
    font-size: $fontSizeXS;
}

.u-text-center {
    text-align: center;
}

.u-bold {
    font-weight: bold;
}

.u-bg-primary {
    background: $secondaryColor;
}

.u-bg-secondary {
    background: $secondaryColor;
}

.u-bg-tertiary {
    background: $tertiaryColor;
}

.u-pos {
    &--relative {
        position: relative;
    }
    &--absolute {
        position: absolute;
    }
}

.u-dn-sm {
    display: none !important;
    @include mq-up(m) {
        display: inherit !important;
    }
}

.u-dn-lg {
    display: none !important;
    @include mq-down(l) {
        display: inherit !important;
    }
}

.u-dn-md {
    display: none !important;
    @include mq-down(m) {
        display: inherit !important;
    }
}