.p-footer {
    padding-top: 40px;
    &__sitemap {
        margin-bottom: 20px;
    }
    &__copyright {
        padding: 15px 0;
        text-align: center;
        font-size: $fontSizeS;
    }
}