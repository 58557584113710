input,
textarea {
    background: #fff;
    border: none;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    max-width: 100%;
    width: 100%;
    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
    }
    &.error {
        background: #fee;
    }
}

textarea {
    height: 200px;
    resize: none;
}

input[type=checkbox] {
    display: none;
}

input[type=checkbox]+label:before {
    font-family: "Font Awesome 5 Free";
    display: inline-block;
}

input[type=checkbox]+label:before {
    content: "\f0c8";
    color: #ccc;
    font-size: 1.6rem;
}


/* unchecked icon */

input[type=checkbox]+label:before {
    letter-spacing: 10px;
}

input[type=checkbox]:checked+label:before {
    content: "\f14a";
    color: $keyColor;
    font-weight: 900;
}

input[type=checkbox]:checked+label:before {}

input[type=file],
input[type=radio] {
    display: none;
}

input[type=radio]+span {
    i {
        margin-right: 5px;
    }
    .checked {
        display: none;
    }
}

input[type=radio]:checked+span {
    i {
        display: none;
    }
    .checked {
        display: inline;
    }
}

.file_label {
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 30px;
    border: solid 1px $keyColor;
    background: white;
    color: $keyColor;
    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
}

.file_label_s {
    padding: 5px 30px;
    font-weight: bold;
    border-radius: 30px;
    border: solid 1px $keyColor;
    background: white;
    color: $keyColor;
    font-size: $fontSizeS;
}