.c-icon {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    overflow: hidden;
    margin: 0 auto 10px auto;
    box-shadow: 0px 0px 15px rgba(#000, 0.05);
    p {
        padding-top: 100%;
    }
}