.u-mt0 {
    margin-top: 0px;
}

.u-mt10 {
    margin-top: 10px;
}

.u-mt20 {
    margin-top: 20px;
}

.u-mt30 {
    margin-top: 30px;
}

.u-mt40 {
    margin-top: 40px;
}

.u-mt50 {
    margin-top: 50px;
}

.u-mt60 {
    margin-top: 60px;
}

.u-mt70 {
    margin-top: 70px;
}

.u-mt80 {
    margin-top: 80px;
}

.u-mt90 {
    margin-top: 90px;
}

.u-mt100 {
    margin-top: 100px;
}

.u-mt0 {
    @include mq-down(m) {
        margin-top: 0px;
    }
}

.u-mt10-sm {
    @include mq-down(m) {
        margin-top: 10px;
    }
}

.u-mt20-sm {
    @include mq-down(m) {
        margin-top: 20px;
    }
}

.u-mt30-sm {
    @include mq-down(m) {
        margin-top: 30px;
    }
}

.u-mt40-sm {
    @include mq-down(m) {
        margin-top: 40px;
    }
}

.u-mt50-sm {
    @include mq-down(m) {
        margin-top: 50px;
    }
}

.u-mt60-sm {
    @include mq-down(m) {
        margin-top: 60px;
    }
}

.u-mt70-sm {
    @include mq-down(m) {
        margin-top: 70px;
    }
}

.u-mt80-sm {
    @include mq-down(m) {
        margin-top: 80px;
    }
}

.u-mt90-sm {
    @include mq-down(m) {
        margin-top: 90px;
    }
}

.u-mt100-sm {
    @include mq-down(m) {
        margin-top: 100px;
    }
}

.u-mb0 {
    margin-bottom: 0px;
}

.u-mb10 {
    margin-bottom: 10px;
}

.u-mb20 {
    margin-bottom: 20px;
}

.u-mb30 {
    margin-bottom: 30px;
}

.u-mb40 {
    margin-bottom: 40px;
}

.u-mb50 {
    margin-bottom: 50px;
}

.u-mb60 {
    margin-bottom: 60px;
}

.u-mb70 {
    margin-bottom: 70px;
}

.u-mb80 {
    margin-bottom: 80px;
}

.u-mb90 {
    margin-bottom: 90px;
}

.u-mb0-sm {
    @include mq-down(m) {
        margin-bottom: 0px;
    }
}

.u-mb10-sm {
    @include mq-down(m) {
        margin-bottom: 10px;
    }
}

.u-mb20-sm {
    @include mq-down(m) {
        margin-bottom: 20px;
    }
}

.u-mb30-sm {
    @include mq-down(m) {
        margin-bottom: 30px;
    }
}

.u-mb40-sm {
    @include mq-down(m) {
        margin-bottom: 40px;
    }
}

.u-mb50-sm {
    @include mq-down(m) {
        margin-bottom: 50px;
    }
}

.u-mb60-sm {
    @include mq-down(m) {
        margin-bottom: 60px;
    }
}

.u-mb70-sm {
    @include mq-down(m) {
        margin-bottom: 70px;
    }
}

.u-mb80-sm {
    @include mq-down(m) {
        margin-bottom: 80px;
    }
}

.u-mb90-sm {
    @include mq-down(m) {
        margin-bottom: 90px;
    }
}

.u-mb100-sm {
    @include mq-down(m) {
        margin-bottom: 100px;
    }
}

.u-pt0 {
    padding-top: 0px;
}

.u-pt10 {
    padding-top: 10px;
}

.u-pt20 {
    padding-top: 20px;
}

.u-pt30 {
    padding-top: 30px;
}

.u-pt40 {
    padding-top: 40px;
}

.u-pt50 {
    padding-top: 50px;
}

.u-pt60 {
    padding-top: 60px;
}

.u-pt70 {
    padding-top: 70px;
}

.u-pt80 {
    padding-top: 80px;
}

.u-pt90 {
    padding-top: 90px;
}

.u-pt0-sm {
    @include mq-down(m) {
        padding-top: 0px;
    }
}

.u-pt10-sm {
    @include mq-down(m) {
        padding-top: 10px;
    }
}

.u-pt20-sm {
    @include mq-down(m) {
        padding-top: 20px;
    }
}

.u-pt30-sm {
    @include mq-down(m) {
        padding-top: 30px;
    }
}

.u-pt40-sm {
    @include mq-down(m) {
        padding-top: 40px;
    }
}

.u-pt50-sm {
    @include mq-down(m) {
        padding-top: 50px;
    }
}

.u-pt60-sm {
    @include mq-down(m) {
        padding-top: 60px;
    }
}

.u-pt70-sm {
    @include mq-down(m) {
        padding-top: 70px;
    }
}

.u-pt80-sm {
    @include mq-down(m) {
        padding-top: 80px;
    }
}

.u-pt90-sm {
    @include mq-down(m) {
        padding-top: 90px;
    }
}

.u-pt100-sm {
    @include mq-down(m) {
        padding-top: 100px;
    }
}

.u-pb0 {
    padding-bottom: 0px;
}

.u-pb10 {
    padding-bottom: 10px;
}

.u-pb20 {
    padding-bottom: 20px;
}

.u-pb30 {
    padding-bottom: 30px;
}

.u-pb40 {
    padding-bottom: 40px;
}

.u-pb50 {
    padding-bottom: 50px;
}

.u-pb60 {
    padding-bottom: 60px;
}

.u-pb70 {
    padding-bottom: 70px;
}

.u-pb80 {
    padding-bottom: 80px;
}

.u-pb90 {
    padding-bottom: 90px;
}

.u-pb0-sm {
    @include mq-down(m) {
        padding-bottom: 0px;
    }
}

.u-pb10-sm {
    @include mq-down(m) {
        padding-bottom: 10px;
    }
}

.u-pb20-sm {
    @include mq-down(m) {
        padding-bottom: 20px;
    }
}

.u-pb30-sm {
    @include mq-down(m) {
        padding-bottom: 30px;
    }
}

.u-pb40-sm {
    @include mq-down(m) {
        padding-bottom: 40px;
    }
}

.u-pb50-sm {
    @include mq-down(m) {
        padding-bottom: 50px;
    }
}

.u-pb60-sm {
    @include mq-down(m) {
        padding-bottom: 60px;
    }
}

.u-pb70-sm {
    @include mq-down(m) {
        padding-bottom: 70px;
    }
}

.u-pb80-sm {
    @include mq-down(m) {
        padding-bottom: 80px;
    }
}

.u-pb90-sm {
    @include mq-down(m) {
        padding-bottom: 90px;
    }
}

.u-pb100-sm {
    @include mq-down(m) {
        padding-bottom: 100px;
    }
}