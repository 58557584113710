.text-keycolor {
    color: $keyColor;
}

.text-bold {
    font-weight: bold;
}

.small {
    font-size: $fontSizeS;
}

.x_small {
    font-size: $fontSizeXS;
}

.text_center {
    text-align: center;
}

.vertical {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-align: left;
    letter-spacing: 0.4rem;
    text-orientation: upright;
    -webkit-text-orientation: upright;
}

a.button,
input[type=submit] {
    display: inline-block;
    padding: 2px 10px;
    font-size: $fontSizeM;
    text-decoration: none;
    @include mq-down(m) {
        padding: 8px 20px;
    }
    &:link {
        text-decoration: none;
    }
}

.caption {
    @include mq-down(m) {
        margin-bottom: 15px;
    }
}

.sp-hidden {
    display: none !important;
    @include mq-up(m) {
        display: block !important;
    }
}

.pc-hidden {
    display: none !important;
    @include mq-down(m) {
        display: block !important;
    }
}

.sp-hidden-inline {
    display: none;
    @include mq-up(m) {
        display: inline;
    }
}

.pc-hidden-inline {
    display: none;
    @include mq-down(m) {
        display: inline;
    }
}

.attention,
.key_color {
    color: $keyColor;
}

.bold {
    font-weight: bold;
}