.c-cat {
    padding: 0px 8px;
    border: solid 1px #ccc;
    background: #fff;
    font-size: $fontSizeXS;
    margin-right: 5px;
    border-radius: 5px;
    &--no_border {
        border: none;
    }
}