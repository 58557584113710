.caption {
    text-align: center;
    margin-bottom: 20px;
}

.cat {
    padding: 0px 8px;
    border: solid 1px #ccc;
    background: #fff;
    font-size: $fontSizeXS;
    margin-right: 5px;
    border-radius: 5px;
    &.no_border {
        border: none;
    }
}

.key_color {
    color: $keyColor;
}

.bg_keycolor {
    background: $keyColor;
}

.bg_yellow {
    background: $yellow;
}

.bg_white {
    background: white !important;
}

.bg_lightgray {
    background: $lightGray;
}

.bg_accent {
    background: $accentColor !important;
}

.h_scroll_container {
    overflow: scroll;
    width: 100%;
    padding-bottom: 10px;
    .flex {
        width: 500px;
    }
    button {
        flex-shrink: 0;
        flex-grow: 0;
        text-align: center;
        padding: 7px 10px;
    }
}

button.plane {
    border: none;
    background: white;
    padding: 5px 10px;
    font-size: $fontSizeS;
    border-radius: 5px;
}

button.button {
    background: $keyColor;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: $fontSizeM;
    &.md {
        padding: 12px 20px;
    }
    &.white {
        background: white;
        border: solid 1px $keyColor;
        color: $keyColor;
    }
    &.gray {
        background: #f5f5f5;
        color: $textGray;
    }
}

button.cat_tab {
    background: #fff;
    border-radius: 5px;
    border: none;
    font-size: $fontSizeS;
    margin-right: 8px;
}

button.round {
    background: #fff;
    font-weight: bold;
    padding: 0px 20px;
    color: $accentColor;
    border: none;
    border-radius: 25px;
    font-size: $fontSizeM;
    height: 40px;
    min-width: 120px;
    &:disabled {
        opacity: 0.4;
    }
}

button.keycolor {
    background: $keyColor;
    color: white;
}

button.brown {
    background: $accentColor;
    color: white;
}

button.white {
    background: white;
    color: $keyColor;
    border: solid 1px $keyColor;
}

button.inline {
    display: inline-block;
    width: auto;
}

.h_scroll_container {
    margin-bottom: 10px;
}

header {
    background: white;
    border-bottom: solid 1px #dcdcdc;
    .container {
        padding: 6px 15px;
    }
    .flex_nw {
        align-items: center;
        justify-content: space-between;
    }
    h1 {
        text-align: center;
        font-size: 1.6rem;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.logo {
            text-align: left;
            font-size: 2.0rem;
            a {
                color: $keyColor;
            }
        }
    }
    .user {
        width: 30px;
        height: 30px;
        flex-basis: 30px;
        .icon {
            border-radius: 20px;
            overflow: hidden;
            display: block;
            p {
                width: 100%;
                padding-top: 100%;
            }
        }
        .name {
            font-size: $fontSizeL;
        }
    }
    .left {
        flex-basis: 40px;
        max-width: 40px;
        text-align: left;
    }
    .right {
        flex-basis: 40px;
        max-width: 40px;
        .icon {
            position: relative;
            color: $accentColor;
            display: block;
            i {
                font-size: 2.2rem;
            }
            span {
                display: block;
                font-size: 1.0rem;
            }
            .num {
                position: absolute;
                top: 0px;
                right: 0px;
                background: red;
                width: 15px;
                height: 15px;
                border-radius: 8px;
                font-weight: bold;
                color: white;
                line-height: 15px;
                display: block;
            }
        }
    }
    .logins {
        padding: 5px 0;
        a {
            font-weight: bold;
            margin-left: 10px;
            font-size: $fontSizeS;
            &.register {
                background: $accentColor;
                color: white;
                padding: 5px 13px;
                border-radius: 20px;
            }
        }
    }
    .back {
        &.lesson {
            position: absolute;
            top: 10px;
            left: 20px;
            z-index: 2;
        }
        a {
            font-size: 2.2rem;
            color: $keyColor;
        }
    }
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    background: #fff;
    .container {
        padding: 0;
    }
    .flex_nw {
        width: 100%;
        padding: 10px 0 5px 0;
        justify-content: space-between;
        .menu_item {
            flex-basis: 20%;
            &.current {
                i {
                    color: #35385f;
                    font-size: 2.2rem;
                }
            }
            i {
                color: #b2b4c4;
                font-size: 2.2rem;
            }
            p {
                margin-top: 4px;
                font-size: 1.0rem;
            }
            img {
                width: 28px;
            }
        }
    }
    .tabbar {
        border-top: solid 1px #eee;
    }
}

.lessons {
    margin-bottom: 20px;
}

.lesson {
    margin-bottom: 10px;
    a {
        text-align: left;
        display: block;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(#000, 0.05);
        &:hover {
            background: #f8f8f8;
        }
    }
    .user {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .icon {
            width: 35px;
            height: 35px;
            flex-basis: 35px;
            border-radius: 20px;
            margin-right: 10px;
            flex-shrink: 0;
            overflow: hidden;
            p {
                padding-top: 100%;
            }
        }
        .name {
            font-size: $fontSizeL;
            font-weight: bold;
            flex-grow: 1;
        }
    }
    .contents {
        margin-bottom: 5px;
        .thumbnail {
            width: 80px;
            flex-basis: 80px;
            flex-shrink: 0;
            margin-right: 15px;
            p {
                padding-top: 100%;
            }
        }
        .info {
            flex-grow: 1;
        }
        .categories {
            margin-bottom: 5px;
            align-items: center;
            .level {
                font-size: $fontSizeXS;
                color: $keyColor;
            }
        }
        h3 {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .description {
            font-size: $fontSizeS;
            margin-bottom: 10px;
            overflow: hidden;
            white-space: wrap;
            text-overflow: ellipsis;
            max-height: 52px;
        }
        .price {
            text-align: right;
            font-weight: bold;
            color: $keyColor;
            font-size: $fontSizeS;
            span {
                font-size: 2.2rem;
                padding-left: 5px;
            }
        }
        .date {
            font-weight: bold;
            font-size: $fontSizeS;
            margin-bottom: 5px;
            color: $textGray;
        }
    }
    .footer {
        display: flex;
        p {
            margin-right: 5px;
        }
        .status {
            font-size: 1.1rem;
            font-weight: bold;
            border-radius: 5px;
            padding: 4px 7px;
            flex-grow: 0;
            flex-shrink: 0;
            border: solid 1px #eee;
            &.process {
                color: $keyColor;
                border: solid 1px $keyColor;
            }
            &.post_waiting {
                color: white;
                background: $keyColor;
                border: solid 1px $keyColor;
            }
            &.reply_waiting {
                color: white;
                background: $accentColor;
                border: solid 1px $accentColor;
            }
        }
        .students {
            background: url(images/icon_user_misoshiru.gif) no-repeat left 8px center / 16px auto;
            font-size: 1.4rem;
            font-weight: bold;
            border-radius: 5px;
            padding: 4px 7px 5px 27px;
            flex-grow: 0;
            flex-shrink: 0;
            color: $keyColor;
            border: solid 1px $keyColor;
        }
    }
}

.teachers {
    margin-bottom: 20px;
}

.teacher,
.user {
    a {
        text-align: left;
        border-bottom: solid 1px #ccc;
        display: block;
        padding: 20px 0;
        .icon {
            width: 35px;
            height: 35px;
            flex-basis: 35px;
            border-radius: 20px;
            overflow: hidden;
            margin-right: 15px;
            flex-shrink: 0;
            p {
                padding-top: 100%;
            }
        }
        .right {
            p {
                margin-bottom: 5px;
            }
            .name {
                font-size: $fontSizeL;
                font-weight: bold;
            }
            .description {
                font-size: $fontSizeS;
            }
        }
    }
    &:first-of-type {
        a {
            border-top: solid 1px #ccc;
        }
    }
}

.user {
    .flex_nw {
        align-items: center;
    }
}

.box_wh {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

.align_center {
    text-align: center;
}

.tab_2 {
    border-radius: 50px;
    overflow: hidden;
    margin-bottom: 20px;
    button {
        margin: 0;
        flex-basis: 50%;
        max-width: 50%;
        background: white;
        color: $keyColor;
        border: none;
        font-weight: bold;
        padding: 7px 10px;
        font-size: $fontSizeS;
        &.current {
            background: $keyColor;
            color: white
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.4);
    z-index: 3;
    .container {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .box_wh {
        width: 100%;
        max-width: 380px;
        margin: 0 auto;
        padding: 30px 20px;
        h3 {
            margin-bottom: 15px;
            font-weight: bold;
        }
        p {
            font-size: $fontSizeM;
            font-weight: bold;
        }
        .description {
            font-size: $fontSizeS;
            font-weight: normal;
            margin-bottom: 20px;
        }
        .flex_nw {
            justify-content: center;
        }
        button.round {
            min-width: 100px;
            margin: 0 5px;
            padding: 0px 15px;
        }
    }
}

.create {
    background: $accentColor;
    width: 100%;
    max-width: 414px;
    .flex_nw {
        justify-content: center;
        align-items: center;
        padding: 10px 0;
    }
    button {
        font-size: $fontSizeS;
        i {
            font-size: $fontSizeM;
            margin-right: 5px;
        }
    }
}

.error_description {
    font-weight: bold;
    color: red;
}