.container {
    width: 100%;
    max-width: 1200px;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    @include mq-down(l) {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    @include mq-down(m) {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        min-width: 320px;
    }
}

.container-fluid {
    width: 100%;
    padding: 70px 15px;
    text-align: center;
    position: relative;
    @include mq-down(m) {
        padding-top: 50px;
        padding-bottom: 50px;
        min-width: 320px;
    }
}

.sp-container-fluid {
    @include mq-down(m) {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
}

div,
p,
ul,
li {
    box-sizing: border-box;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.flex_nw {
    display: flex;
    flex-wrap: nowrap;
}

.col_10 {
    flex-basis: 10%;
    max-width: 10%;
}

.col_20 {
    flex-basis: 20%;
    max-width: 20%;
}

.col_25 {
    flex-basis: 25%;
    max-width: 25%;
}

.col_33 {
    flex-basis: 33.3%;
    max-width: 33.3%;
}

.col_30 {
    flex-basis: 30%;
    max-width: 30%;
}

.col_40 {
    flex-basis: 40%;
    max-width: 40%;
}

.col_50 {
    flex-basis: 50%;
    max-width: 50%;
}

.col_60 {
    flex-basis: 60%;
    max-width: 60%;
}

.col_70 {
    flex-basis: 70%;
    max-width: 70%;
}

.col_80 {
    flex-basis: 80%;
    max-width: 80%;
}

.col_90 {
    flex-basis: 90%;
    max-width: 90%;
}

.col_100 {
    flex-basis: 100%;
    max-width: 100%;
}

.col_auto {
    flex-basis: auto
}

.sp_col_10 {
    @include mq-down(m) {
        flex-basis: 10%;
        max-width: 10%;
    }
}

.sp_col_20 {
    @include mq-down(m) {
        flex-basis: 20%;
        max-width: 20%;
    }
}

.sp_col_25 {
    @include mq-down(m) {
        flex-basis: 25%;
        max-width: 25%;
    }
}

.sp_col_33 {
    @include mq-down(m) {
        flex-basis: 33.3%;
        max-width: 33.3%;
    }
}

.sp_col_30 {
    @include mq-down(m) {
        flex-basis: 30%;
        max-width: 30%;
    }
}

.sp_col_40 {
    @include mq-down(m) {
        flex-basis: 40%;
        max-width: 40%;
    }
}

.sp_col_50 {
    @include mq-down(m) {
        flex-basis: 50%;
        max-width: 50%;
    }
}

.sp_col_60 {
    @include mq-down(m) {
        flex-basis: 60%;
        max-width: 60%;
    }
}

.sp_col_70 {
    @include mq-down(m) {
        flex-basis: 70%;
        max-width: 70%;
    }
}

.sp_col_80 {
    @include mq-down(m) {
        flex-basis: 80%;
        max-width: 80%;
    }
}

.sp_col_90 {
    @include mq-down(m) {
        flex-basis: 100%;
        max-width: 100%;
    }
}

.sp_col_100 {
    @include mq-down(m) {
        flex-basis: 100%;
        max-width: 100%;
    }
}

.mt0 {
    margin-top: 0px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mt40 {
    margin-top: 40px;
}

.mt50 {
    margin-top: 50px;
}

.mt60 {
    margin-top: 60px;
}

.mt70 {
    margin-top: 70px;
}

.mt80 {
    margin-top: 80px;
}

.mt90 {
    margin-top: 90px;
}

.mt100 {
    margin-top: 100px;
}

.sp_mt0 {
    @include mq-down(m) {
        margin-top: 0px;
    }
}

.sp_mt10 {
    @include mq-down(m) {
        margin-top: 10px;
    }
}

.sp_mt20 {
    @include mq-down(m) {
        margin-top: 20px;
    }
}

.sp_mt30 {
    @include mq-down(m) {
        margin-top: 30px;
    }
}

.sp_mt40 {
    @include mq-down(m) {
        margin-top: 40px;
    }
}

.sp_mt50 {
    @include mq-down(m) {
        margin-top: 50px;
    }
}

.sp_mt60 {
    @include mq-down(m) {
        margin-top: 60px;
    }
}

.sp_mt70 {
    @include mq-down(m) {
        margin-top: 70px;
    }
}

.sp_mt80 {
    @include mq-down(m) {
        margin-top: 80px;
    }
}

.sp_mt90 {
    @include mq-down(m) {
        margin-top: 90px;
    }
}

.sp_mt100 {
    @include mq-down(m) {
        margin-top: 100px;
    }
}

.mb0 {
    margin-bottom: 0px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb70 {
    margin-bottom: 70px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb90 {
    margin-bottom: 90px;
}

.sp_mb0 {
    @include mq-down(m) {
        margin-bottom: 0px;
    }
}

.sp_mb10 {
    @include mq-down(m) {
        margin-bottom: 10px;
    }
}

.sp_mb20 {
    @include mq-down(m) {
        margin-bottom: 20px;
    }
}

.sp_mb30 {
    @include mq-down(m) {
        margin-bottom: 30px;
    }
}

.sp_mb40 {
    @include mq-down(m) {
        margin-bottom: 40px;
    }
}

.sp_mb50 {
    @include mq-down(m) {
        margin-bottom: 50px;
    }
}

.sp_mb60 {
    @include mq-down(m) {
        margin-bottom: 60px;
    }
}

.sp_mb70 {
    @include mq-down(m) {
        margin-bottom: 70px;
    }
}

.sp_mb80 {
    @include mq-down(m) {
        margin-bottom: 80px;
    }
}

.sp_mb90 {
    @include mq-down(m) {
        margin-bottom: 90px;
    }
}

.sp_mb100 {
    @include mq-down(m) {
        margin-bottom: 100px;
    }
}

.pt0 {
    padding-top: 0px;
}

.pt10 {
    padding-top: 10px;
}

.pt20 {
    padding-top: 20px;
}

.pt30 {
    padding-top: 30px;
}

.pt40 {
    padding-top: 40px;
}

.pt50 {
    padding-top: 50px;
}

.pt60 {
    padding-top: 60px;
}

.pt70 {
    padding-top: 70px;
}

.pt80 {
    padding-top: 80px;
}

.pt90 {
    padding-top: 90px;
}

.sp_pt0 {
    @include mq-down(m) {
        padding-top: 0px;
    }
}

.sp_pt10 {
    @include mq-down(m) {
        padding-top: 10px;
    }
}

.sp_pt20 {
    @include mq-down(m) {
        padding-top: 20px;
    }
}

.sp_pt30 {
    @include mq-down(m) {
        padding-top: 30px;
    }
}

.sp_pt40 {
    @include mq-down(m) {
        padding-top: 40px;
    }
}

.sp_pt50 {
    @include mq-down(m) {
        padding-top: 50px;
    }
}

.sp_pt60 {
    @include mq-down(m) {
        padding-top: 60px;
    }
}

.sp_pt70 {
    @include mq-down(m) {
        padding-top: 70px;
    }
}

.sp_pt80 {
    @include mq-down(m) {
        padding-top: 80px;
    }
}

.sp_pt90 {
    @include mq-down(m) {
        padding-top: 90px;
    }
}

.sp_pt100 {
    @include mq-down(m) {
        padding-top: 100px;
    }
}

.pb0 {
    padding-bottom: 0px;
}

.pb10 {
    padding-bottom: 10px;
}

.pb20 {
    padding-bottom: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

.pb40 {
    padding-bottom: 40px;
}

.pb50 {
    padding-bottom: 50px;
}

.pb60 {
    padding-bottom: 60px;
}

.pb70 {
    padding-bottom: 70px;
}

.pb80 {
    padding-bottom: 80px;
}

.pb90 {
    padding-bottom: 90px;
}

.sp_pb0 {
    @include mq-down(m) {
        padding-bottom: 0px;
    }
}

.sp_pb10 {
    @include mq-down(m) {
        padding-bottom: 10px;
    }
}

.sp_pb20 {
    @include mq-down(m) {
        padding-bottom: 20px;
    }
}

.sp_pb30 {
    @include mq-down(m) {
        padding-bottom: 30px;
    }
}

.sp_pb40 {
    @include mq-down(m) {
        padding-bottom: 40px;
    }
}

.sp_pb50 {
    @include mq-down(m) {
        padding-bottom: 50px;
    }
}

.sp_pb60 {
    @include mq-down(m) {
        padding-bottom: 60px;
    }
}

.sp_pb70 {
    @include mq-down(m) {
        padding-bottom: 70px;
    }
}

.sp_pb80 {
    @include mq-down(m) {
        padding-bottom: 80px;
    }
}

.sp_pb90 {
    @include mq-down(m) {
        padding-bottom: 90px;
    }
}

.sp_pb100 {
    @include mq-down(m) {
        padding-bottom: 100px;
    }
}