.p-header {
    background: white;
    border-bottom: solid 1px #dcdcdc;
    .c-container {
        padding: 6px 15px;
    }
    .flex_nw {
        align-items: center;
        justify-content: space-between;
    }
    h1 {
        text-align: center;
        font-size: 1.6rem;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.logo {
            text-align: left;
            font-size: 2.0rem;
            a {
                color: $keyColor;
            }
        }
    }
    &--user {
        width: 30px;
        height: 30px;
        flex-basis: 30px;
        .icon {
            border-radius: 20px;
            overflow: hidden;
            display: block;
            p {
                width: 100%;
                padding-top: 100%;
            }
        }
        .name {
            font-size: $fontSizeL;
        }
    }
    &--left {
        flex-basis: 40px;
        max-width: 40px;
        text-align: left;
    }
    &--right {
        flex-basis: 40px;
        max-width: 40px;
        .icon {
            position: relative;
            color: $accentColor;
            display: block;
            i {
                font-size: 2.2rem;
            }
            span {
                display: block;
                font-size: 1.0rem;
            }
            .num {
                position: absolute;
                top: 0px;
                right: 0px;
                background: red;
                width: 15px;
                height: 15px;
                border-radius: 8px;
                font-weight: bold;
                color: white;
                line-height: 15px;
                display: block;
            }
        }
    }
    &--logins {
        padding: 5px 0;
        a {
            font-weight: bold;
            margin-left: 10px;
            font-size: $fontSizeS;
            &.register {
                background: $accentColor;
                color: white;
                padding: 5px 13px;
                border-radius: 20px;
            }
        }
    }
    &--back {
        &.lesson {
            position: absolute;
            top: 10px;
            left: 20px;
            z-index: 2;
        }
        a {
            font-size: 2.2rem;
            color: $keyColor;
        }
    }
}