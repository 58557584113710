.c-tab {
    &--col-2 {
        border-radius: 50px;
        overflow: hidden;
        margin-bottom: 20px;
        .c-button {
            margin: 0;
            flex-basis: 50%;
            max-width: 50%;
            background: white;
            color: $keyColor;
            border: none;
            font-weight: bold;
            padding: 7px 10px;
            font-size: $fontSizeS;
            &.is-current {
                background: $keyColor;
                color: white
            }
        }
    }
}