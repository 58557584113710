    .search_page {
        .list_box {
            margin-bottom: 15px;
            h3 {
                padding: 5px 20px;
                font-weight: bold;
            }
            ul {
                background: white;
                li {
                    position: relative;
                    border-bottom: solid 1px $yellow;
                    background: url(../images/icon_arrow_right_gr.png) no-repeat right 20px center / 7px auto;
                    span {
                        position: absolute;
                        padding: 15px 20px;
                        font-weight: bold;
                        font-size: $fontSizeS;
                    }
                    input,
                    select {
                        width: 100%;
                        text-align: right;
                        border: none;
                        padding: 15px 20px;
                    }
                    select {
                        padding: 15px 20px;
                        direction: rtl;
                        box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -webkit-appearance: none;
                        padding-right: 35px;
                        background: none;
                        i {
                            color: $textColor;
                        }
                    }
                }
                .checkbox {
                    background: none;
                    .name {
                        display: flex;
                        justify-content: space-between;
                        padding: 15px 20px;
                        align-items: center;
                    }
                    span {
                        padding: 0;
                        display: block;
                        position: static;
                    }
                    .name {
                        i {
                            color: #ccc;
                        }
                        &.active {
                            i {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    .items {
                        display: none;
                    }
                    label {
                        display: block;
                        padding: 0px 0px 0px 20px;
                        background: #f5f5f5;
                        p {
                            display: flex;
                            justify-content: space-between;
                            padding: 12px 20px 12px 10px;
                            border-top: solid 1px #dcdcdc;
                            span {
                                padding: 0;
                            }
                            i {
                                text-align: right;
                                margin: 0 0 0 auto;
                                display: none;
                                color: $keyColor;
                            }
                        }
                        input:checked+p {
                            i {
                                display: block;
                            }
                        }
                    }
                    input {
                        width: 100%;
                        display: none;
                    }
                }
            }
        }
        .result {
            position: relative;
            overflow-x: hidden;
            min-height: 100vh;
            .page_header {
                position: relative;
                margin-bottom: 10px;
            }
            .result_header {
                .result_num {
                    font-weight: bold;
                    span {
                        font-size: 2.4rem;
                    }
                }
                .filter {
                    position: absolute;
                    right: 0;
                    top: 0;
                    i {
                        color: $keyColor;
                        margin-right: 5px;
                    }
                }
            }
            &.show_filter {}
            .filter_box {
                .bg {
                    background: rgba($color: #000000, $alpha: 0.3);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    min-height: 100%;
                }
                .inner {
                    background: $yellow;
                    min-height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 70%;
                    z-index: 0;
                }
                .container {
                    min-width: auto;
                }
            }
            .show_filter_bg-enter-active,
            .show_filter_bg-leave-active {
                transition: all 0.4s ease;
            }
            .show_filter_inner-enter-active,
            .show_filter_inner-leave-active {
                transition: all 0.4s ease;
            }
            .show_filter_bg-enter,
            .show_filter_bg-leave-to {
                opacity: 0;
            }
            .show_filter_inner-enter,
            .show_filter_inner-leave-to {
                transform: translateX(-70vh);
            }
        }
    }