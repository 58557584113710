.login_page,
.register_page {
    h1:not(.logo) {
        margin-bottom: 10px;
    }
    .box {
        padding: 35px 25px;
        border-radius: 5px;
        text-align: left;
        margin-bottom: 20px;
        box-shadow: 0px 0px 15px rgba($color: #000000, $alpha: 0.05);
        .item {
            margin-bottom: 15px;
            p {
                font-weight: bold;
                margin-bottom: 5px;
            }
            input {
                border: solid 1px #dfdfdf;
                margin-bottom: 5px;
            }
            .skip {
                margin-top: 20px;
                text-align: center;
                a {
                    font-weight: normal;
                    i {
                        color: $keyColor;
                        margin-left: 5px;
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
    .other_buttons {
        p {
            text-align: right;
            margin-bottom: 50px;
        }
        a {
            i {
                color: $keyColor;
                margin-left: 5px;
                font-size: 1.6rem;
            }
        }
        .inner {
            padding: 0 20px;
        }
        .button {
            margin-bottom: 50px;
        }
        .button.login {
            background: white;
            border: solid 1px $keyColor;
            color: $keyColor;
        }
    }
}