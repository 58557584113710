.p-lesson {
    margin-bottom: 10px;
    a {
        text-align: left;
        display: block;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(#000, 0.05);
        &:hover {
            background: #f8f8f8;
        }
    }
    &__user {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        &__icon {
            width: 35px;
            height: 35px;
            flex-basis: 35px;
            border-radius: 20px;
            margin-right: 10px;
            flex-shrink: 0;
            overflow: hidden;
            p {
                padding-top: 100%;
            }
        }
        &__name {
            font-size: $fontSizeL;
            font-weight: bold;
            flex-grow: 1;
        }
    }
    &__contents {
        margin-bottom: 5px;
        &__thumbnail {
            width: 80px;
            flex-basis: 80px;
            flex-shrink: 0;
            margin-right: 15px;
            p {
                padding-top: 100%;
            }
        }
        &__info {
            flex-grow: 1;
        }
        &__categories {
            margin-bottom: 5px;
            align-items: center;
            .level {
                font-size: $fontSizeXS;
                color: $keyColor;
            }
        }
        h3 {
            font-weight: bold;
            margin-bottom: 10px;
        }
        &__description {
            font-size: $fontSizeS;
            margin-bottom: 10px;
            overflow: hidden;
            white-space: wrap;
            text-overflow: ellipsis;
            max-height: 52px;
        }
        &__price {
            text-align: right;
            font-weight: bold;
            color: $keyColor;
            font-size: $fontSizeS;
            span {
                font-size: 2.2rem;
                padding-left: 5px;
            }
        }
        &__date {
            font-weight: bold;
            font-size: $fontSizeS;
            margin-bottom: 5px;
            color: $textGray;
        }
    }
    &__footer {
        display: flex;
        p {
            margin-right: 5px;
        }
        &__status {
            font-size: 1.1rem;
            font-weight: bold;
            border-radius: 5px;
            padding: 4px 7px;
            flex-grow: 0;
            flex-shrink: 0;
            border: solid 1px #eee;
            &--is-process {
                color: $keyColor;
                border: solid 1px $keyColor;
            }
            &--is-post-waiting {
                color: white;
                background: $keyColor;
                border: solid 1px $keyColor;
            }
            &--is-reply-waiting {
                color: white;
                background: $accentColor;
                border: solid 1px $accentColor;
            }
        }
        &__students {
            background: url(images/icon_user_misoshiru.gif) no-repeat left 8px center / 16px auto;
            font-size: 1.4rem;
            font-weight: bold;
            border-radius: 5px;
            padding: 4px 7px 5px 27px;
            flex-grow: 0;
            flex-shrink: 0;
            color: $keyColor;
            border: solid 1px $keyColor;
        }
    }
}