.l-container {
    width: 100%;
    max-width: 1200px;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    @include mq-down(l) {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    @include mq-down(m) {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        min-width: 320px;
    }
}

.l-container-fluid {
    width: 100%;
    padding: 70px 15px;
    text-align: center;
    position: relative;
    @include mq-down(m) {
        padding-top: 50px;
        padding-bottom: 50px;
        min-width: 320px;
    }
}

.l-container-fluid-sm {
    @include mq-down(m) {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
}