.c-list {
    margin-bottom: 20px;
    &__title {
        padding: 5px 20px;
    }
    &__item {
        background: white;
        a {
            display: flex;
            flex-wrap: nowrap;
            padding: 15px 20px 15px 0px;
            margin-left: 20px;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid 1px #dcdcdc;
            font-weight: bold;
            font-size: $fontSizeS;
            i {
                color: $textGray;
            }
            &.is-active {
                i {
                    transform: rotate(180deg);
                }
            }
        }
        &--plain {
            background: none;
            a {
                padding: 5px 20px 5px 0px;
                border: none;
            }
        }
        &:last-of-type {
            a {
                border: none;
            }
        }
        &__contents {
            display: none;
            background: #f5f5f5;
            padding: 20px;
            p {
                font-size: $fontSizeS;
            }
            ul {
                padding-left: 20px;
                li {
                    list-style-type: decimal;
                    margin-bottom: 10px;
                    font-size: $fontSizeS;
                }
            }
        }
    }
}