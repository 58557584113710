.p-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.4);
    z-index: 3;
    .c-container {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .c-box {
        width: 100%;
        max-width: 380px;
        margin: 0 auto;
        padding: 30px 20px;
        h3 {
            margin-bottom: 15px;
            font-weight: bold;
        }
        p {
            font-size: $fontSizeM;
            font-weight: bold;
        }
        .description {
            font-size: $fontSizeS;
            font-weight: normal;
            margin-bottom: 20px;
        }
        .flex_nw {
            justify-content: center;
        }
        .c-button--round {
            min-width: 100px;
            margin: 0 5px;
            padding: 0px 15px;
        }
    }
}