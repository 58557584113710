.c-tabbar {
    background: #fff;
    .c-container {
        padding: 0;
    }
    &__inner {
        display: flex;
        flex-wrap: nowrap;
        text-align: center;
        width: 100%;
        padding: 10px 0 5px 0;
        justify-content: space-between;
        border-top: solid 1px #eee;
    }
    &__item {
        flex-basis: 20%;
        &.is-current {
            i {
                color: #35385f;
                font-size: 2.2rem;
            }
        }
        i {
            color: #b2b4c4;
            font-size: 2.2rem;
        }
        p {
            margin-top: 4px;
            font-size: 1.0rem;
        }
        img {
            width: 26px;
            margin-bottom: 0;
        }
    }
}