.p-user {
    a {
        text-align: left;
        border-bottom: solid 1px #ccc;
        display: block;
        padding: 20px 0;
        .icon {
            width: 35px;
            height: 35px;
            flex-basis: 35px;
            border-radius: 20px;
            overflow: hidden;
            margin-right: 15px;
            flex-shrink: 0;
            p {
                padding-top: 100%;
            }
        }
        &--right {
            p {
                margin-bottom: 5px;
            }
        }
        &--name {
            font-size: $fontSizeL;
            font-weight: bold;
        }
        &--description {
            font-size: $fontSizeS;
        }
    }
    &:first-of-type {
        a {
            border-top: solid 1px #ccc;
        }
    }
}

.p-teacher {
    @extend .p-user;
}

.p-user {
    .flex_nw {
        align-items: center;
    }
}