.p-create {
    background: $accentColor;
    width: 100%;
    max-width: 414px;
    .flex_nw {
        justify-content: center;
        align-items: center;
        padding: 10px 0;
    }
    button {
        font-size: $fontSizeS;
        i {
            font-size: $fontSizeM;
            margin-right: 5px;
        }
    }
}