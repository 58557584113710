.lesson_page {
    padding-bottom: 20px;
    h2 {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: $fontSizeL;
        text-align: left;
    }
    .lesson_header {
        .container {
            padding: 0;
        }
        .video {
            width: 100%;
            video,
            img {
                width: 100%;
            }
        }
    }
    .user_teacher {
        text-align: left;
        align-items: center;
        margin-bottom: 20px;
        .icon {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            flex-shrink: 0;
            border-radius: 18px;
            overflow: hidden;
            p {
                padding-top: 100%;
            }
        }
        .name {
            p {
                font-weight: bold;
                font-size: $fontSizeXS;
            }
            a {
                font-size: 1.8rem;
            }
        }
    }
    .current_students {
        text-align: center;
        background: white;
        margin-bottom: 30px;
        a {
            padding: 10px 20px;
            font-weight: bold;
            font-size: 1.2rem;
            color: $accentColor;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                font-weight: bold;
                display: block;
            }
            .num {
                font-size: 2.6rem;
                margin-left: 7px;
            }
        }
    }
    .description {
        text-align: left;
        p:not(.short) {
            white-space: pre-wrap;
            word-wrap: break-word;
        }
        a.toggle {
            text-align: center;
            font-weight: bold;
            font-size: $fontSizeS;
            display: block;
            margin-bottom: 30px;
            i {
                margin-left: 10px;
            }
        }
        p,
        ul {
            margin-bottom: 20px;
        }
        h1 {
            text-align: left;
            font-size: 2.0rem;
            margin-bottom: 10px;
        }
        .categories {
            margin-bottom: 5px;
            align-items: center;
        }
        .level {
            font-size: $fontSizeXS;
            color: $accentColor;
            margin-left: 10px;
        }
        h3 {
            color: $keyColor;
            font-weight: bold;
            margin-bottom: 5px;
        }
        ul {
            padding-left: 15px;
            li {
                list-style-type: decimal;
                font-size: $fontSizeM;
                margin-bottom: 5px;
            }
        }
        .title {
            font-size: $fontSizeS;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .attachment {
            a {
                color: $accentColor;
                font-weight: bold;
                font-size: $fontSizeS;
                text-decoration: underline;
                i {
                    margin-right: 3px;
                }
            }
        }
        .about_teacher {
            text-align: left;
            align-items: center;
            margin-bottom: 10px;
            .icon {
                width: 35px;
                height: 35px;
                margin-right: 10px;
                flex-shrink: 0;
                border-radius: 18px;
                overflow: hidden;
                p {
                    padding-top: 100%;
                }
            }
            .name {
                p {
                    font-weight: bold;
                    font-size: $fontSizeXS;
                }
                a {
                    font-size: 1.4rem;
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }
        .lesson_info {
            padding-top: 0;
            padding-bottom: 0;
            dl {
                font-size: $fontSizeS;
                justify-content: space-between;
                align-items: center;
                padding: 15px 0;
                border-bottom: solid 1px #eee;
                &:last-of-type {
                    border-bottom: none;
                }
                dt {
                    font-weight: bold;
                }
            }
        }
        .notice {
            margin-bottom: 40px;
            padding: 20px 15px;
            p {
                background: url(images/lesson_icon_point.png) no-repeat left top 5px / 26px auto;
                margin-bottom: 0;
                padding-left: 40px;
                font-weight: bold;
                min-height: 40px;
            }
        }
        .next {
            margin: 10px auto 50px auto;
            text-align: center;
            p {
                font-weight: bold;
                font-size: $fontSizeM;
                margin-bottom: 10px;
            }
            button {
                border-radius: 50px;
                width: 90px;
                height: 90px;
                padding: 0;
                overflow: hidden;
                background: white;
                border: solid 2px #ffe2b1;
                p {
                    width: 100%;
                    font-size: 4.4rem;
                    color: $keyColor;
                    margin: 0;
                }
            }
        }
    }
    .steps {
        padding: 0;
        margin-bottom: 40px;
        li {
            a,
            .lesson_complete {
                padding: 15px 20px;
                border-bottom: solid 1px $yellow;
                display: flex;
                text-align: left;
                flex-wrap: nowrap;
                align-items: center;
                &.complete {
                    color: #aaa;
                }
            }
            .step {
                font-size: $fontSizeXS;
                flex-basis: 45px;
                flex-grow: 0;
                i {
                    display: block;
                    padding-left: 8px;
                }
            }
            p {
                flex-grow: 1;
                font-weight: bold;
                text-align: left;
            }
            i {
                flex-basis: 20px;
                flex-shrink: 0;
                max-width: 20px;
                text-align: right;
                font-size: 1.6rem;
            }
            .fa-check {
                color: $keyColor;
                text-align: left;
                margin-right: 5px;
            }
            &.try {
                a {
                    background: $keyColor;
                    color: white;
                    text-align: center;
                }
            }
        }
    }
    .purchase {
        position: fixed;
        background: $accentColor;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        .flex_nw {
            margin: 0 auto;
            max-width: 640px;
            justify-content: space-between;
            align-items: center;
            .price {
                color: white;
                font-size: 2.0rem;
                text-align: left;
                line-height: 1.0em;
                padding: 5px 0;
                font-weight: bold;
                span {
                    font-size: $fontSizeXS;
                    display: block;
                }
            }
        }
        button {
            font-size: $fontSizeS;
        }
    }
    .action {
        background: $accentColor;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;
        a {
            font-weight: bold;
            font-size: $fontSizeS;
            text-align: center;
            color: white;
        }
        .complete {
            a {
                color: white;
                font-size: $fontSizeS;
                text-align: left;
                line-height: 1.0em;
                padding: 15px 0;
                font-weight: bold;
                display: block;
            }
        }
        button {
            font-size: $fontSizeS;
        }
    }
    .types {
        width: 100%;
        margin-bottom: 15px;
        border-radius: 20px;
        overflow: hidden;
        a {
            display: block;
            padding: 10px 0;
            font-weight: bold;
            font-size: $fontSizeS;
            color: $keyColor;
            background: #fff;
            flex-basis: 50.0%;
            max-width: 50.0%;
            border-right: solid 1px $yellow;
            &.current {
                background: $keyColor;
                color: #fff;
            }
        }
    }
    .feedback {
        text-align: left;
        padding: 0;
        .box {
            padding: 20px 20px 0 20px;
            .user {
                align-items: center;
                margin-bottom: 10px;
                .icon {
                    width: 35px;
                    height: 35px;
                    border-radius: 18px;
                    overflow: hidden;
                    margin-right: 10px;
                    p {
                        padding-top: 100%;
                    }
                }
                .name {
                    flex-grow: 1;
                    font-weight: bold;
                }
                .date {
                    font-size: $fontSizeS;
                    font-weight: bold;
                    color: #aaa;
                }
            }
            .evaluation {
                .score {
                    background: #fdeccd;
                    border-radius: 5px;
                    padding: 5px;
                    text-align: center;
                    margin-bottom: 15px;
                    margin-top: 18px;
                    font-weight: bold;
                    letter-spacing: 0.1rem;
                    color: $keyColor;
                    span {
                        font-size: 2.8rem;
                    }
                }
                .description {
                    font-weight: bold;
                    text-align: center;
                    font-size: 1.6rem;
                    margin-bottom: 7px;
                    color: $keyColor;
                }
                &.first {
                    .score {
                        background: #e4b115;
                        color: white;
                        padding-right: 20px;
                        span {
                            background: url(images/lesson_rank_01_2.png) no-repeat left center / 26px auto;
                            padding: 0 0px 0 32px;
                        }
                    }
                    .description {
                        color: #e4b115;
                    }
                }
                &.second {
                    .score {
                        background: #a8b9c2;
                        color: white;
                        padding-right: 20px;
                        span {
                            background: url(images/lesson_rank_02_2.png) no-repeat left center / 26px auto;
                            padding: 0 0px 0 32px;
                        }
                    }
                    .description {
                        color: #a8b9c2;
                    }
                }
                &.third {
                    .score {
                        background: #d18567;
                        color: white;
                        padding-right: 20px;
                        span {
                            background: url(images/lesson_rank_03_2.png) no-repeat left center / 26px auto;
                            padding: 0 0px 0 32px;
                        }
                    }
                    .description {
                        color: #d18567;
                    }
                }
            }
            .contents {
                p {
                    font-size: $fontSizeM;
                    padding-bottom: 20px;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                }
                .video {
                    video,
                    img {
                        width: 100%;
                    }
                    padding-bottom: 20px;
                }
            }
            &.reply {
                border-top: solid 1px #ddd;
                .relpay_icon {
                    border: solid 1px $textColor;
                    background: white;
                    font-size: 1.0rem;
                    padding: 2px 4px;
                    border-radius: 5px;
                    display: inline-block;
                    font-weight: bold;
                    margin-bottom: 10px;
                    i {
                        margin-right: 3px;
                    }
                }
            }
            &.reply_post {
                padding-bottom: 30px;
                .notice {
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 10px;
                    color: #f50000;
                    i {
                        margin-right: 3px;
                        letter-spacing: 0;
                    }
                }
                .button {
                    background: $accentColor;
                    color: white;
                    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
                    max-width: 240px;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        .ranking_item {
            border-top: solid 1px #ddd;
            &:first-of-type {
                border-top: none;
            }
            .head {
                align-items: center;
                padding-bottom: 18px;
                .rank {
                    font-weight: bold;
                    font-size: 1.5rem;
                    margin-right: 10px;
                    color: #aaa;
                    width: 26px;
                    text-align: center;
                }
                .user {
                    flex-grow: 1;
                    margin-bottom: 0;
                }
                .score {
                    padding: 0;
                    margin: 0;
                    background: none;
                    font-weight: bold;
                    font-size: 2.0rem;
                    margin-right: 20px;
                    color: #aaa;
                }
                .fa-chevron-down {
                    color: #ccc;
                }
            }
            &.first {
                .rank {
                    color: #e4b115;
                    background: url(images/lesson_rank_01.png) no-repeat center center / 26px auto;
                    padding: 12px 0;
                }
                .score {
                    color: #e4b115;
                }
            }
            &.second {
                .rank {
                    color: #a8b9c2;
                    background: url(images/lesson_rank_02.png) no-repeat center center / 26px auto;
                    padding: 12px 0;
                }
                .score {
                    color: #a8b9c2;
                }
            }
            &.third {
                .rank {
                    background: url(images/lesson_rank_03.png) no-repeat center center / 26px auto;
                    padding: 12px 0;
                    color: #d18567;
                }
                .score {
                    color: #d18567;
                }
            }
            &.active {
                .fa-chevron-down {
                    transform: rotate(180deg);
                }
            }
        }
        .qa_item {
            border-top: solid 1px #ddd;
            &:first-of-type {
                border-top: none;
            }
            .head {
                align-items: center;
                padding-bottom: 18px;
                h3 {
                    margin-bottom: 2px;
                    .new {
                        background: $accentColor;
                        border-radius: 5px;
                        font-weight: bold;
                        font-size: 1.0rem;
                        color: white;
                        padding: 2px 5px;
                        transform: translateY(-1px);
                        display: inline-block;
                        margin-right: 5px;
                    }
                }
                .left {
                    flex-grow: 1;
                    margin-right: 15px;
                    div {
                        align-items: center;
                        p {
                            color: #777;
                            font-size: 1.2rem;
                        }
                        .name {
                            margin-right: 10px;
                        }
                        .reply_date {
                            flex-grow: 1;
                            text-align: right;
                            i {
                                color: #aaa;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                i {
                    color: $keyColor;
                }
                &.active {
                    .fa-chevron-down {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .inner {
            display: none;
            padding-left: 20px;
            background: #f5f5f5;
            .box {
                border-top: solid 1px #ddd;
                padding-left: 0;
                &:first-of-type {
                    border-top: none;
                }
            }
        }
    }
    h3 {
        font-weight: bold;
        font-size: $fontSizeM;
        margin-bottom: 10px;
    }
    .modal {
        &.step_doit {
            .item {
                flex-basis: 50%;
                max-width: 50%;
            }
            button {
                background: none;
                border: none;
                img {
                    width: 90px;
                    height: auto;
                }
            }
            p {
                font-size: $fontSizeS;
            }
        }
    }
    /* å®æ¼æç¨¿ãã¼ã¸ */
    .post {
        min-height: calc(100vh - 50px);
        .container {
            text-align: left;
            h3 {
                margin-bottom: 5px;
            }
            .box {
                margin-bottom: 15px;
                dl {
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 15px;
                    background: #fff;
                    border-radius: 10px;
                    margin-bottom: 5px;
                    dt {
                        font-size: $fontSizeS;
                        font-weight: bold;
                    }
                    dd {
                        font-size: 3.2rem;
                        color: $keyColor;
                    }
                }
                p {
                    font-size: $fontSizeS;
                }
                .fa-toggle-off {
                    color: $yellow;
                }
            }
            .file_box {
                padding: 20px 20px 50px 20px;
                border-radius: 10px;
                text-align: center;
                background: white;
                video {
                    width: 100%;
                    margin-bottom: 20px;
                }
                img {
                    width: 100%;
                    margin-bottom: 20px;
                }
                .upload_icon {
                    font-size: 4.0rem;
                    padding: 20px 0 20px 0;
                    color: $keyColor;
                    opacity: 0.7;
                }
            }
            ul {
                border-radius: 10px;
                background: white;
                li {
                    position: relative;
                    border-bottom: solid 1px $yellow;
                    background: url(../images/icon_arrow_right_gr.png) no-repeat right 20px center / 7px auto;
                    span {
                        position: absolute;
                        padding: 15px 20px;
                        font-weight: bold;
                        font-size: $fontSizeS;
                    }
                    input,
                    select {
                        width: 100%;
                        text-align: right;
                        border: none;
                        padding: 15px 20px;
                    }
                    select {
                        padding: 15px 20px;
                        direction: rtl;
                        box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -webkit-appearance: none;
                        padding-right: 35px;
                        background: none;
                        i {
                            color: $textColor;
                        }
                    }
                }
            }
        }
        &.create_intro {
            .error_description {
                margin-top: 5px;
            }
            .price {
                background: white;
                border-radius: 10px;
                input {
                    width: calc(100% - 40px);
                    margin-right: 10px;
                }
            }
            .step_box {
                padding: 40px 20px 40px 20px;
                border-radius: 10px;
                text-align: center;
                background: white;
                .button {
                    padding: 10px 30px;
                    font-weight: bold;
                    border-radius: 30px;
                    border: solid 1px $keyColor;
                    background: white;
                    color: $keyColor;
                    width: auto;
                    box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.2);
                }
            }
            .buttons {
                padding: 20px;
                margin-top: 30px;
                .button {
                    margin-bottom: 15px;
                }
            }
            .currentStep_box {
                border-radius: 10px;
                padding: 0 0 30px 0;
                ul {
                    background: none;
                    margin-bottom: 20px;
                }
                li {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    padding: 18px 20px;
                    background: none;
                    p {
                        font-weight: bold;
                        text-align: left;
                        font-size: $fontSizeM;
                    }
                    .step {
                        flex-shrink: 0;
                        margin-right: 15px;
                        font-size: 1.1rem;
                    }
                }
                .button {}
            }
        }
        &.create_steps {
            .box.step {
                background: white;
                padding: 0px 0 0 0;
                border-radius: 10px;
                overflow: hidden;
                .file_box {
                    padding: 10px 20px 20px 20px;
                    .inner {
                        padding-bottom: 30px;
                        border-radius: 10px;
                        border: dotted 2px $yellow;
                    }
                }
                h2 {
                    background: $keyColor;
                    color: white;
                    font-weight: bold;
                    padding: 10px 20px;
                    margin-bottom: 20px;
                    text-align: center;
                }
                h3 {
                    padding-left: 20px;
                    margin-bottom: 0;
                }
                input,
                textarea {
                    padding-left: 20px;
                }
                .box {
                    border-bottom: solid 1px $yellow;
                    border-radius: 0;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                textarea.notice {
                    height: 60px;
                }
            }
            .add_step {
                background: white;
                padding: 13px 20px;
                border-radius: 10px;
                margin-bottom: 50px;
                i {
                    margin-right: 5px;
                    font-size: 1.6rem;
                }
            }
            .buttons {
                padding: 20px;
                margin-top: 30px;
                .button {
                    margin-bottom: 15px;
                }
            }
        }
    }
    /* åè¬èãã¼ã¸ */
    &.lesson_users {
        .lesson {
            a {
                background: $yellow;
                box-shadow: none;
            }
        }
        .users {
            .user {
                a {
                    padding: 12px 20px;
                    border-bottom: solid 1px $yellow;
                    .right {
                        flex-grow: 1;
                    }
                    .status {
                        text-align: right;
                        font-weight: bold;
                        color: $accentColor;
                        margin-right: 10px;
                        font-size: $fontSizeS;
                        i {
                            color: $accentColor;
                            transform: translateY(3px);
                            margin-right: 5px;
                        }
                    }
                    i {
                        color: $keyColor;
                        font-size: 2.0rem;
                    }
                }
                &:first-of-type a {
                    border-top: none;
                }
            }
        }
    }
}