// @mixinã®å®ç¾©

/*----------------------------------------
media query
----------------------------------------*/

@mixin mq-up($breakpoint: l) {
    @media #{map-get($breakpoint-up, $breakpoint)} {
        @content;
    }
}

@mixin mq-down($breakpoint: l) {
    @media #{map-get($breakpoint-down, $breakpoint)} {
        @content;
    }
}

@mixin centerImgBox($width:100px, $height:100px) {
    position: relative;
    width: $width;
    height: $height;
    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }
}

@mixin slideInT() {
    transition: all 400ms 0.2s ease;
    transform: translateY(-120%);
    &.active {
        transform: translateY(0%);
    }
}

@mixin slideInR() {
    transition: all 400ms 0.2s ease;
    transform: translateX(100%);
    &.active {
        transform: translateX(0%);
    }
}

@mixin clearfix {
    display: inline-block;
    position: relative;
    /* DW */
    display: block;
    &:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        height: 0px;
        font-size: 0.1px;
        line-height: 0.1px;
    }
}

@mixin boxShadowS() {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}

@mixin boxShadowL() {
    box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.2);
}

@mixin col1Space($space:0px) {
    padding-bottom: $space;
    &:nth-of-type(4n-3) {
        padding-left: 0;
        padding-right: 0;
    }
    &:nth-of-type(4n-2) {
        padding-left: 0;
        padding-right: 0;
    }
    &:nth-of-type(4n-1) {
        padding-left: 0;
        padding-right: 0;
    }
    &:nth-of-type(4n) {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin col2Space($space:0px) {
    padding-bottom: $space;
    &:nth-of-type(2n-1) {
        padding-left: 0;
        padding-right: $space * 1/2;
    }
    &:nth-of-type(2n) {
        padding-left: $space * 1/2;
        padding-right: 0;
    }
}

@mixin col3Space($space:0px) {
    padding-bottom: $space;
    &:nth-of-type(3n-2) {
        padding-left: 0;
        padding-right: $space * 2/3;
    }
    &:nth-of-type(3n-1) {
        padding-left: $space * 1/3;
        padding-right: $space * 1/3;
    }
    &:nth-of-type(3n) {
        padding-left: $space * 2/3;
        padding-right: 0;
    }
}

@mixin col4Space($space:0px) {
    padding-bottom: $space;
    &:nth-of-type(4n-3) {
        padding-left: 0;
        padding-right: $space * 3/4;
    }
    &:nth-of-type(4n-2) {
        padding-left: $space * 1/4;
        padding-right: $space * 2/4;
    }
    &:nth-of-type(4n-1) {
        padding-left: $space * 2/4;
        padding-right: $space * 1/4;
    }
    &:nth-of-type(4n) {
        padding-left: $space * 3/4;
        padding-right: 0;
    }
}

@mixin categoryColor($color) {
    .cat_color {
        color: $color;
    }
    .cat_border {
        border-color: $color;
    }
    .cat_background {
        background-color: $color !important;
        i {
            display: inline-block;
            height: 20px;
            width: 100px;
        }
    }
}