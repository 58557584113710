.user_page {
    .user_header {
        .icon {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            overflow: hidden;
            margin: 0 auto 10px auto;
            box-shadow: 0px 0px 15px rgba(#000, 0.05);
            p {
                padding-top: 100%;
            }
        }
        .name {
            p {
                font-size: $fontSizeXS;
                font-weight: bold;
            }
            h1 {
                font-size: $fontSizeL;
            }
        }
    }
    .profile {
        p {
            text-align: left;
            margin-bottom: 20px;
            font-size: $fontSizeS;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
        .categories {
            margin-bottom: 10px;
        }
        .career {
            font-weight: bold;
            color: $textGray;
            margin-bottom: 0px;
        }
    }
    .count {
        text-align: left;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 10px;
        margin-left: 10px;
        span {
            font-size: 2.6rem;
            margin-right: 3px;
        }
    }
    h2 {
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 1.6rem;
    }
}