.my_page {
    .user_header {
        .icon {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            overflow: hidden;
            margin: 0 auto 10px auto;
            box-shadow: 0px 0px 15px rgba(#000, 0.05);
            p {
                padding-top: 100%;
            }
        }
        .name {
            p {
                font-size: $fontSizeXS;
                font-weight: bold;
            }
            h1 {
                font-size: $fontSizeL;
            }
        }
    }
    .list_box {
        margin-bottom: 20px;
        li {
            padding-left: 20px;
            background: white;
            a {
                display: flex;
                flex-wrap: nowrap;
                padding: 15px 20px 15px 0;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid 1px #dcdcdc;
                font-weight: bold;
                i {
                    color: $textGray;
                }
            }
            &:last-of-type {
                a {
                    border: none;
                }
            }
        }
        .small {
            li {
                a {}
            }
        }
        .error_description {
            padding: 5px 20px 0 20px;
        }
    }
    &.edit {
        .user_header {
            .icon {
                margin-bottom: 20px;
            }
        }
        .input_box {
            border: none;
            h2 {
                padding: 5px 20px;
                font-weight: bold;
            }
            ul {
                background: white;
            }
            li {
                position: relative;
                padding-left: 10px;
                span {
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    text-align: right;
                    font-size: $fontSizeS;
                    pointer-events: none;
                    color: $textGray;
                }
            }
            &.select_box {
                li {
                    border-bottom: solid 1px $yellow;
                    background: url(../images/icon_arrow_right_gr.png) no-repeat right 20px center/7px auto;
                    span {
                        position: absolute;
                        padding: 15px 20px;
                        left: 0px;
                        top: 0px;
                        right: auto;
                        font-weight: bold;
                        font-size: $fontSizeS;
                        color: $textColor;
                    }
                }
            }
            li.checkbox {
                background: none;
                padding-left: 0;
                .name {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 20px;
                    align-items: center;
                }
                span {
                    padding: 0;
                    display: block;
                    position: static;
                }
                .name {
                    i {
                        color: #ccc;
                    }
                    span {
                        flex-shrink: 0;
                    }
                    .selsected {
                        text-align: right;
                        flex-grow: 1;
                        flex-shrink: 1;
                        margin: 0 10px;
                        font-weight: normal;
                    }
                    &.active {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }
                .items {
                    display: none;
                }
                label {
                    display: block;
                    padding: 0px 0px 0px 20px;
                    background: #f5f5f5;
                    p {
                        display: flex;
                        justify-content: space-between;
                        padding: 12px 20px 12px 10px;
                        border-top: solid 1px #dcdcdc;
                        span {
                            padding: 0;
                        }
                        i {
                            text-align: right;
                            margin: 0 0 0 auto;
                            display: none;
                            color: $keyColor;
                        }
                    }
                    input:checked+p {
                        i {
                            display: block;
                        }
                    }
                }
                input {
                    width: 100%;
                    display: none;
                }
            }
            select {
                width: 100%;
                text-align: right;
                border: none;
                padding: 18px 20px;
            }
            select {
                padding: 18px 20px;
                direction: rtl;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -webkit-appearance: none;
                padding-right: 35px;
                background: none;
                i {
                    color: $textColor;
                }
            }
            input,
            textarea {
                font-weight: bold;
                background: none;
            }
        }
    }
    &.contact {
        .input_box {
            border: none;
            h2 {
                padding: 5px 20px;
                font-weight: bold;
            }
            ul {
                background: white;
            }
            li {
                position: relative;
                padding-left: 10px;
                span {
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    text-align: right;
                    font-size: $fontSizeS;
                    pointer-events: none;
                    color: $textGray;
                }
            }
            input,
            textarea {
                font-weight: bold;
                background: none;
            }
            &.radio {
                p {
                    padding: 15px 20px;
                    background: white;
                    border-bottom: solid 1px $yellow;
                    font-weight: bold;
                }
            }
        }
    }
    &.sales {
        .sales_container {
            p {
                font-weight: bold;
            }
            h2 {
                font-weight: bold;
                font-size: $fontSizeS;
                margin-bottom: 10px;
            }
            .sales {
                font-weight: bold;
                color: $accentColor;
                margin-bottom: 15px;
                span {
                    font-size: 3.0rem;
                }
            }
            .history {
                margin-bottom: 40px;
                a {
                    display: block;
                    background: white;
                    text-align: right;
                    padding: 15px 20px;
                    font-weight: bold;
                    color: $accentColor;
                    i {
                        margin-left: 10px;
                        font-size: 1.8rem;
                    }
                }
            }
            .status {
                background: $accentColor;
                border-radius: 15px;
                padding: 5px 15px;
                color: white;
                font-size: $fontSizeS;
                display: inline-block;
                min-width: 120px;
                margin-bottom: 20px;
                &.complete {
                    background: black;
                }
            }
            .dates {
                justify-content: center;
                p {
                    margin: 0 10px;
                }
            }
        }
        .sales_item {
            a {
                text-align: left;
                border-bottom: solid 1px $yellow;
                display: block;
                padding: 20px;
                .thumbnail {
                    width: 80px;
                    flex-basis: 80px;
                    flex-shrink: 0;
                    margin-right: 15px;
                    p {
                        padding-top: 100%;
                    }
                }
                .right {
                    flex-grow: 1;
                    .user {
                        align-items: center;
                        margin-bottom: 10px;
                        .icon {
                            width: 25px;
                            height: 25px;
                            flex-basis: 25px;
                            border-radius: 20px;
                            overflow: hidden;
                            margin-right: 15px;
                            p {
                                padding-top: 100%;
                            }
                        }
                    }
                    p {
                        margin-bottom: 5px;
                    }
                    .name {
                        font-size: $fontSizeL;
                        font-weight: bold;
                        flex-grow: 1;
                    }
                    .price {
                        font-weight: bold;
                        color: $textColor;
                        text-align: right;
                        span {
                            font-size: 1.8rem;
                        }
                        .small {
                            font-size: 1.2rem;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
    .transfar {
        background: white;
        border-radius: 10px;
        padding: 15px 20px;
        margin-bottom: 10px;
        .flex_nw {
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            p {
                font-weight: bold;
            }
        }
        .head {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: solid 1px $yellow;
            .prceed {
                font-size: $fontSizeL;
                ;
            }
        }
        .status {
            background: $accentColor;
            border-radius: 15px;
            padding: 5px 15px;
            color: white;
            font-size: $fontSizeS;
            &.complete {
                background: black;
            }
        }
    }
    &.purchased_users {
        .page_header {
            .result_num {
                text-align: center;
                font-weight: bold;
                span {
                    font-size: 2.4rem;
                }
            }
        }
        .user {
            a {
                padding: 12px 20px;
                border-bottom: solid 1px $yellow;
                .right {
                    flex-grow: 1;
                }
                .status {
                    text-align: right;
                    font-weight: bold;
                    color: $accentColor;
                    margin-right: 10px;
                    font-size: $fontSizeS;
                    i {
                        color: $accentColor;
                        transform: translateY(3px);
                        margin-right: 5px;
                    }
                }
                i {
                    color: $keyColor;
                    font-size: 2.0rem;
                }
            }
            &:first-of-type a {
                border-top: none;
            }
        }
    }
}