.p-h-scroll-container {
    overflow: scroll;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .c-flex {
        width: 500px;
    }
    .c-button {
        flex-shrink: 0;
        flex-grow: 0;
        text-align: center;
        padding: 7px 10px;
    }
}